<template>
    <div>
        <ValidationObserver ref="payment">
            <div class="row pl-md-5 px-sm-2">
                <div>
                    <h4 style="color: #707070">{{ $t('complate_your_purchase') }}</h4>
                    <p style="color: #797979">{{ $t('ticket_message') }}</p>
                </div>
                <div class="col-12 col-md-6 mb-2">
                    <div class="row">
                        <div class="col-6 col-md-12">
                            <ValidationProvider name="card_number" rules="required" v-slot="{ errors }">
                                <b-form-group :label="$t('credit_card_number')">
                                    <b-form-input type="text" :state="errors[0] ? false : null"
                                                  v-mask="'#### #### #### ####'"
                                                  style="font-weight:bold"
                                                  v-model="payData.card_number" />
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-md-12">
                            <ValidationProvider name="card_holder_name" rules="required" v-slot="{ errors }">
                                <b-form-group :label="$t('credit_card_name_surname')">
                                    <b-form-input v-model="payData.card_holder_name" :state="errors[0] ? false : null"
                                                  v-uppercase
                                                  style="font-weight:bold"
                                                  type="text" />
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <ValidationProvider name="expiry_month" rules="required" v-slot="{ errors }">
                                <b-form-group :label="$t('month')">
                                    <b-form-select v-model="payData.expiry_month" :state="errors[0] ? false : null"
                                                   :options="['01', '02', '03', '04', '05', '06', '07', '08', '09', 10, 11, 12]"></b-form-select>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-md-4">
                            <ValidationProvider name="expiry_year" rules="required" v-slot="{ errors }">
                                <b-form-group :label="$t('year')">
                                    <b-form-select v-model="payData.expiry_year" :options="years"
                                                   :state="errors[0] ? false : null"></b-form-select>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-md-4">
                            <ValidationProvider name="cvv" rules="required" v-slot="{ errors }">
                                <b-form-group :label="'CVV'">
                                    <b-form-input type="text"
                                                  v-model="payData.cvv"
                                                  v-mask="'###'"
                                                  :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div @click="openModal('privacyNotice')">
                            <ValidationProvider name="privacyNotice.status" rules="oneOf:1" v-slot="{ errors }">
                                <b-form-checkbox class="mt-2" @change="privacyNotice.status = false"
                                                 :unchecked-value="false" :value="true" v-model="privacyNotice.status"
                                                 :state="errors[0] ? false : null">
                                    <span v-html="$t('privacy_notice_approve')"></span>
                                </b-form-checkbox>
                                <b-form-invalid-feedback v-if="errors[0]">{{ $t('statement3_error')
                                    }}</b-form-invalid-feedback>
                            </ValidationProvider>
                        </div>
                        <div @click="openModal('privacyPolicy')">
                            <ValidationProvider name="privacyPolicy.status" rules="oneOf:1" v-slot="{ errors }">
                                <b-form-checkbox class="mt-2" @change="privacyPolicy.status = false"
                                                 :unchecked-value="false" :value="true" v-model="privacyPolicy.status"
                                                 :state="errors[0] ? false : null">
                                    <span v-html="$t('privacy_policy_approve')"></span>
                                </b-form-checkbox>
                                <b-form-invalid-feedback v-if="errors[0]">{{ $t('statement3_error')
                                    }}</b-form-invalid-feedback>
                            </ValidationProvider>
                        </div>
                        <div @click="openModal('distanceContract')">
                            <ValidationProvider name="distanceContract.status" rules="oneOf:1" v-slot="{ errors }">
                                <b-form-checkbox class="mt-2" @change="distanceContract.status = false"
                                                 :unchecked-value="false" :value="true" v-model="distanceContract.status"
                                                 :state="errors[0] ? false : null">
                                    <span v-html="$t('distance_contract_approve')"></span>
                                </b-form-checkbox>
                                <b-form-invalid-feedback v-if="errors[0]">{{ $t('statement3_error')
                                    }}</b-form-invalid-feedback>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="ticket-details mb-5">
                        <div class="header">{{ $t('event_ticket_details').toUpper() }}</div>
                        <div class="content mt-2">
                            <p class="m-0">{{ $t('total') + ':' }} {{ currencyFormat(this.$props.amount) }} TL</p>
                            <p class="m-0">KDV (%20): {{ currencyFormat(vatAmount) }}</p>
                            <p class="m-0">{{ $t('discount') + ':' }} {{ '%' + promotionCodeDiscount }}</p>
                            <p>{{ $t('grand_total') + ':' }} {{ currencyFormat(this.$props.totalAmount) }} TL</p>
                            <p class="note">
                                {{ $t('ticket_message1')}}<br>
                                {{ $t('ticket_message2')}}<br>
                                {{ $t('ticket_message3')}}
                            </p>
                            <button @click="makePayment" :disabled="isDisabled" class="payment-button">{{ $t('approve').toUpper() }}</button>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-md-4 mt-2" v-if="bankInstallmentError">
                    <div class="border rounded p-4 mt-4">
                        <div class="row">
                            <div class="col-12">
                                <b-alert variant="danger" show>
                                    <p class="text-center" style="font-size: 36px;"><i class="ri-information-line"></i></p>
                                    <p>{{ $t('credit_card_installment_error') }}</p>
                                </b-alert>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
        <CommonModal ref="privacyNoticeModal" size="lg">
            <template v-slot:CommonModalTitle>
                <div> {{ $t('privacy_notice').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div class="row">
                    <div class="col-12 mb-4" style="white-space: pre-line;">
                        <p v-html="$t('privacy_notice_message')"></p>
                    </div>
                </div>
                <b-button style="background-color: #f653fb" type="button" @click="approve('privacyNotice')">{{ $t('read_and_accept')
                    }}</b-button>
            </template>
        </CommonModal>
        <CommonModal ref="privacyPolicyModal" size="lg">
            <template v-slot:CommonModalTitle>
                <div> {{ $t('privacy_policy').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div class="row">
                    <div class="col-12 mb-4" style="white-space: pre-line;">
                        <p v-html="$t('privacy_policy_message')"></p>
                    </div>
                </div>
                <b-button style="background-color: #f653fb" type="button" @click="approve('privacyPolicy')">{{ $t('read_and_accept')
                    }}</b-button>
            </template>
        </CommonModal>
        <CommonModal ref="distanceContractModal" size="lg">
            <template v-slot:CommonModalTitle>
                <div> {{ $t('distance_sales_agreement').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div class="row">
                    <div class="col-12 mb-4" style="white-space: pre-line;">
                        <p v-html="$t('distance_contract_message')"></p>
                    </div>
                </div>
                <b-button style="background-color: #f653fb" type="button" @click="approve('distanceContract')">{{ $t('read_and_accept')
                    }}</b-button>
            </template>
        </CommonModal>
        <CommonModal ref="paymentResponseModal" size="lg">
            <template v-slot:CommonModalContent>
                <div class="row">
                    <div class="col-12 mb-4" style="white-space: pre-line;">
                        <iframe sandbox="allow-same-origin allow-top-navigation allow-forms allow-scripts"
                                :srcdoc="iframeSrc" frameborder="1" id="payment-frame"></iframe>
                    </div>
                </div>
            </template>
        </CommonModal>
    </div>
</template>

<script>
// Components

// Services
import TicketEventApplicationService from "@/services/TicketEventApplicationService";

// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate';

export default {
    components: {

        ValidationProvider,
        ValidationObserver
    },
    props: {
        eventType: {
            type: Number,
            default: null
        },
        totalAmount: {
            type: Number,
            default: null
        },
        amount: {
            type: Number,
            default: null
        },
        ticketCount: {
            type: Number,
            default: null
        },
        promotionCodeDiscount: {
            type: Number,
            default: null
        },
        vatAmount:{
            type: Number,
            default: null
        },
        id: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            payData: {},
            privacyNotice: {
                show: false,
                status: false,
                read: false
            },
            privacyPolicy: {
                show: false,
                status: false,
                read: false
            },
            distanceContract: {
                show: false,
                status: false,
                read: false
            },
            iframeSrc:null,
            bankInstallmentError: null,
            paymentId: null,
            isDisabled: false
        };
    },
    methods: {
        openModal(str, status = true) {
            if (status) {
                this.$refs[`${str}Modal`].$refs.commonModal.show()
            } else {
                this.$refs[`${str}Modal`].$refs.commonModal.hide()
            }

        },
        approve(str) {
            this.$data[str].status = true
            this.openModal(str, false)
        },
      async  makePayment() {
          const isValid = await this.$refs.payment.validate();
          this.isDisabled = true;
          setTimeout(() => {
              this.isDisabled = false;
          }, 10000);
          if (!isValid) {
              return
          }
            let form = {
                ...this.payData,
                ticket_event_application_id: this.eventId,
                card_number: this.payData.card_number.replace(/\s/g, ''),
            }
            try {
                const res =await TicketEventApplicationService.payment(form)
                 this.iframeSrc =res.data
                 await this.showModal()
            }catch (error) {
                this.showErrors(error)
            }
        },
        getPaymentRes() {
            let paymentData = {
                ticket_event_application_id: this.eventId,
                secret: this.secretData
            }
            TicketEventApplicationService.getPaymentResponse(paymentData)
                .then((response) => {
                    const paymentRes = response.data.data.status
                    if(paymentRes === 'approved'){
                        this.$store.dispatch('ticketSystem/initTickets',response.data.data)
                        this.redirectToTickets()
                    }else if(paymentRes === 'waiting_payment'){
                        this.$emit('openPayment');
                    } else {
                        this.$emit('openPayment');
                    }
                })
                .catch((error) => {
                    if (error.data.message) {
                        this.$toast.error(this.$t("api." + error.data.message));
                    }
                });
        },
        async showModal() {
            this.$refs.paymentResponseModal.$refs.commonModal.show();
            this.$refs.paymentResponseModal.$refs.commonModal.$on('hidden', async () => {
                await this.getPaymentRes();
            });
        },
        redirectToTickets(){
            this.$router.push('/success/message');
        },
    },
    computed: {
        years() {
            let year = new Date().getFullYear()
            let index = 0
            const nums = []

            while (index < 11) {
                nums.push(year + index)
                index++
            }

            return nums
        },
        eventId(){
            return this.$store.getters['ticketSystem/getEventId']
        },
        secretData(){
            return this.$store.getters['ticketSystem/getSecret']
        }
    },
}
</script>

<style lang="scss" scoped>
#payment-frame {
    width: 100%;
    height: 34rem;
}

 .ticket-details {
     border: 1px solid #ddd;
     border-radius: 8px;
     padding: 20px;
     max-width: 400px;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
 }

.header {
    background-color: #f653fb;
    color: white;
    padding: 10px;
    border-radius: 8px 8px 0 0;
    text-align: center;
    font-weight: bold;
}

.payment-button {
    width: 100%;
    padding: 10px;
    background-color: #f653fb;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.note {
    font-size: 12px;
    margin-top: 10px;
    color: #555;
}
</style>
