<template>
        <div class="email-verification-container">
            <loading v-show="pageLoading" />
            <div v-show="!pageLoading">
                <div class="mb-3">
                    <ValidationObserver ref="formEmailVerify">
                        <ValidationProvider name="email_code" rules="required" v-slot="{ valid, errors }">
                            <sms-input
                                v-model="emailCode"
                                :timerFrom="emailTimer"
                                @timerExpired="timerExpired"
                                class="responsive-sms-input"
                            />
                        </ValidationProvider>
                    </ValidationObserver>
                </div>
                <b-row>
                    <b-col>
                        <b-button
                            type="button"
                            style="background-color: #f653fb"
                            color="white"
                            size="lg"
                            block
                            @click="emailVerify"
                            :disabled="emailTimer == 0"
                            class="responsive-button"
                        >
                            {{ $t('verify').toUpperCase() }}
                        </b-button>
                    </b-col>
                    <b-col>
                        <b-button
                            type="submit"
                            variant="outline-secondary"
                            size="lg"
                            block
                            @click="emailSend()"
                            :disabled="emailTimer > 0"
                            class="responsive-button"
                        >
                            {{ $t('send_again').toUpperCase() }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </div>
    </template>

<script>
// Components
import Loading from '@/components/elements/Loading.vue';
import SmsInput from '@/components/elements/SmsInput.vue';

// Services
import TicketEventApplicationService from "@/services/TicketEventApplicationService";

// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate';

export default {
    components: {
        Loading,
        SmsInput,
        ValidationProvider,
        ValidationObserver
    },
    props: {
        emailAddress: {
            type: String,
            default: ''
        },
        eventType: {
            type: Number,
            default: null
        },
        file: {}
    },
    data() {
        return {
            // Page
            pageLoading: false,

            // Form
            formLoading: false,

            // Email
            email: this.emailAddress,
            emailTimer: 60,
            emailCode: '',
            evenTypes: this.eventType,
        }
    },
    methods: {
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        async emailVerify() {
            const isValid = await this.$refs.formEmailVerify.validate();
            if (isValid) {
                const formData = new FormData();
                formData.append('email', this.emailAddress);
                formData.append('verification_code', this.emailCode);
                formData.append('ticket_event_type_id', this.evenTypes);
                if (this.evenTypes === 3) {
                    formData.append('file', this.file);
                }

                this.formLoading = true;
                try {
                    const response = await TicketEventApplicationService.emailVerify(formData);
                    const id = response.data.data.id;
                    const secret = response.data.data.secret;
                    const statusMessage = response.data.data.status;
                    if (statusMessage === "waiting" && this.evenTypes === 1 && this.evenTypes === 2) {
                        this.$emit('closeEmail');
                    }
                    else if (statusMessage === "approved") {
                        let paymentData = {
                            ticket_event_application_id: id,
                            secret: secret
                        }
                        TicketEventApplicationService.getPaymentResponse(paymentData)
                            .then((response) => {
                                this.$store.dispatch('ticketSystem/initTickets', response.data.data)
                                this.$store.dispatch('ticketSystem/initEventId', id)
                                this.$store.dispatch('ticketSystem/initSecret', secret)
                                this.$router.push('/success/message');
                            })
                            .catch((error) => {
                                if (error.data.message) {
                                    this.$toast.error(this.$t("api." + error.data.message));
                                }
                            });
                    } else if (statusMessage === "waiting" && this.evenTypes === 3) {
                        this.$emit('closeStudentModal');
                    } else if (statusMessage === "waiting" && this.evenTypes === 4) {
                        this.$emit('closeOrganizationModal');
                    } else {
                        this.$store.dispatch('ticketSystem/initEventId', id)
                        this.$store.dispatch('ticketSystem/initSecret', secret)
                        this.$emit('closeModal');
                    }
                } catch (e) {
                    this.$toast.error(this.$t('verify_code_error'));
                } finally {
                    this.formLoading = false;
                }
            }
        },
        timerExpired(event) {
            this.emailTimer = 0;
        },
        emailSend() {
            this.emailTimer = 60;
        }
    }
}
</script>

<style scoped>
.email-verification-container {
    padding: 10px;
}

.responsive-sms-input input {
    font-size: 12px;
    height: 50px;
    width: 45px;
    text-align: left;
    margin: 0 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-weight: 700;
    color: #000;
}

.responsive-button {
    font-size: 16px;
    padding: 15px 10px;
}

@media (max-width: 768px) {
    .responsive-sms-input input {
        font-size: 12px;
        height: 40px;
        width: 35px;
    }

    .responsive-button {
        font-size: 12px;
        padding: 12px 8px;
    }
}

@media (max-width: 576px) {
    .responsive-sms-input input {
        font-size: 12px;
        height: 100px;
        width: 30px;
        margin: 0 3px;
    }

    .responsive-button {
        font-size: 12px;
        padding: 10px 6px;
    }

    .email-verification-container {
        padding: 10px;
    }
}
</style>
